<template>
  <vx-card title="Banners versión móvil">
    <p>Aquí se pueden modificar los Banners que aparecen en el Home.</p>
    <div v-if="initProgress" style="margin-top: 1rem; width: 100%">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>
    <div class="mt-5">
      <!-- Banners -->
      <div class="flex flex-wrap -mx-2">
        <div class="w-1/2 px-2" v-for="(banner, index) in content.bannersMobile" :key="index">
          <div>
            <!-- Image Container -->
            <div class="img-container flex items-center justify-center">
              <img :src="banner.image" alt="img" class="responsive">

            </div>
            <!-- Image upload Buttons -->
            <div class="modify-img flex justify-between mt-5">
              <input type="file" class="hidden" :id="`banner${index}`"
                     @change="updateBanner($event,banner)"
                     accept="image/*">
              <span style="cursor: pointer" class="ml-2" type="flat"
                    @click="changeBanner(`banner${index}`)">Cambiar
                                </span>
              <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                    @click="deleteImage(index)">Eliminar</span>
            </div>
          </div>
          <div class="mt-5">
            <vs-input @blur="banner.path= trimString(banner.path)" label="Path"
                      v-model="banner.path"
                      class="mt-5 w-full" name="path"
                      v-validate="'required'"/>
          </div>
          <hr class="mt-5 mb-5">
        </div>
      </div>
      <!--Add image-->
      <vs-button style="width: 100%" @click="addBanner">Agregar banner (680 x 260)</vs-button>
      <!--End-->
      <div class="flex mt-6 flex-wrap items-center" slot="footer">
        <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
        <div v-if="progress" style="margin-top: 1rem; width: 100%">
          <vs-progress indeterminate color="primary"></vs-progress>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import trimString from "../mixins/trimString";

const firebase = require("firebase")
// Required for side-effects
require("firebase/firestore")
let db = firebase.firestore()

export default {
  name: "BannersMobile",
  mixins: [
    trimString
  ],
  data() {
    return {
      content: {
        bannersMobile: []
      },
      progress: false,
      initProgress: false
    }
  },
  created() {
    this.initProgress = true
    db.collection("content").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        this.content.id = doc.id
        this.content.bannersMobile = doc.data().bannersMobile ? doc.data().bannersMobile : []
      })
      this.initProgress = false
    })
  },
  computed: {
    isFormValid() {
      return !this.errors.any()
    }
  },
  methods: {
    submitData() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          this.progress = true

          // Content
          let contentRef = db.collection('content').doc(this.content.id)

          //Clone to remove the id
          let cloneContent = Object.assign({}, this.content)
          delete cloneContent.id

          contentRef.update({
            ...cloneContent,
            uid: firebase.auth().currentUser.uid,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp()
          }).then(() => {
            this.progress = false
            this.$vs.notify({
              color: 'success',
              title: 'Banners',
              text: 'Banners modificados correctamente.'
            })
          }).catch(() => {
            this.progress = false
            this.$vs.notify({
              color: 'warning',
              title: '¡Oh no!',
              text: 'Hubo un error.'
            })
          })
        }
      })
    },
    async updateBanner(input, banner) {
      this.progress = true
      if (input.target.files && input.target.files[0]) {
        banner.image = await this.submitImages(input.target.files[0])
      }
      this.progress = false
    },
    deleteImage(index) {
      this.content.bannersMobile.splice(index, 1)
    },
    submitImages(image) {
      return new Promise((resolve, reject) => {
        let imageName = Math.random().toString(36).substr(1, 20)
        let fileExtension = '.' + image.name.split('.').pop()
        let imageNameFinal = imageName + fileExtension

        let storageRef = firebase.storage().ref(`content/banners/${imageNameFinal}`)
        storageRef.put(image)
            .then((snapshot) => {
              snapshot.ref.getDownloadURL().then((url) => {
                resolve(url)
              })
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    changeBanner(id) {
      const banner = document.getElementById(id)
      banner.click()
    },
    addBanner() {
      let obj = {
        position: 'NN',
        image: 'https://via.placeholder.com/800x600/FFFF00/000000'
      }
      this.content.bannersMobile.push(obj)
    }
  }
}
</script>
